import { deepCopy, Engine, FlatStructuralTransformer, getOptionId, PModel, SinglePropertyMutator } from "@canvas-logic/engine";
import { RootStore } from "./RootStore";
import {
  CentralBoxLocation,
  CylinderCutout,
  HeightFromGround,
  ICentralBox,
  ICountry,
  IMailBox,
  Intercom,
  IntercomCutoutManufacturer,
  IntercomSetting,
  IWizard,
  MailboxLockType,
  MountingType,
  ParcelLockType,
  WallLayout,
  WallLocation,
  WallType
} from "../schema";
import { ModelMutator } from "@canvas-logic/engine/dist/engine";
import { Option } from "@canvas-logic/engine/dist/option";
import { makeAutoObservable, toJS } from "mobx";
import { WizardSerializer } from "../serialization";
import { ZFormLayoutGenerator } from "../generator/ZFormLayoutGenerator";
import { InternaGenerator } from "../generator/InternaGenerator";
import { BoxisGenerator } from "../generator/BoxisGenerator";

export const OTHER_COUNTRY = 'content.options.countries.custom';
export const MAX_CUT_OUT_HEIGHT = (wallType: WallType) => {
  if (wallType === WallType.Interna) {
    return 470;
  }
  if (wallType === WallType.Boxis) {
    return 735;
  }
  return 550;
};
export const MAX_CUT_OUT_WIDTH = (wallType: WallType) => {
  if (wallType === WallType.Interna) {
    return 290;
  }
  if (wallType === WallType.Boxis) {
    return 240;
  }
  return 180;
};
export const MAX_LENGTH_INTERCOM_NAME = 30;
export const MAX_LENGTH_COUNTRY = 30;
const MAX_COLUMNS_AMOUNT = 12;
const MAX_RECTANGLE_ROWS = 30;
const MAX_STANDALONE_ROWS = 36;
const FIX_ON_THE_GROUND_HEIGHT_FROM_GROUND = [1500, 1600, 1700, 1800];
const FIX_ON_THE_CONCRETE_HEIGHT_FROM_GROUND = [1200, 1600, 1800];

const MIN_BOX_COUNT_COMPLIANCE_OPTIONS = ['boxis_540_505', 'boxis_584_d_505'];

export class WizardStore {
  private engine: Engine;
  doorBellsEnabled = true;
  customDoorBellsAmount: number | undefined = undefined;

  model: PModel<IWizard>;
  private serializer: WizardSerializer;

  constructor(rootStore: RootStore, private _wallType: WallType, link: string) {
    this.serializer = new WizardSerializer(rootStore.datasetService);
    const result = link ? this.serializer.fromLink(link) : this.serializer.getDefault(_wallType);
    this.model = result.wizard;
    this.engine = result.engine;
    this._wallType = this.model.wallType;

    // we don't know whether the amount of bells was set by user, or by mailbox count
    // so we treat this value as custom anyway
    if (link && !(this.model.hasCustomIntercom || !this.model.hasIntercom)) {
      this.customDoorBellsAmount = this.model.doorBellsAmount;
    }

    console.log(result.wizard);

    this.setHasIntercom(this.model.hasIntercom);
    makeAutoObservable(this);
  }

  get isMechanical() {
    return this._wallType === WallType.Mechanical;
  }

  get isInterna() {
    return this._wallType === WallType.Interna;
  }

  get isBoxis() {
    return this._wallType === WallType.Boxis;
  }
  get isAlbo(): boolean {
    return this.isBoxis || this.isInterna;
  }

  get isDigital() {
    return this._wallType === WallType.Digital;
  }

  get wallType() {
    return this._wallType;
  }

  get availableCountries() {
    return this.engine.optionValuesByPath(this.model, 'country', new FlatStructuralTransformer<ICountry>()) ?? [];
  }

  get availableWallLocations() {
    return this.engine.propertyValuesByPath(this.model, 'wallLocation');
  }

  get availableLayouts() {
    if (this.isBoxis || this.isInterna) {
      return [];
    }
    const layouts = (this.engine.propertyValuesByPath(this.model, 'layout') ?? []) as WallLayout[];
    if (this.model.wallLocation === WallLocation.OutsideUnsheltered) {
      return layouts.filter((layout: WallLayout) => layout !== WallLayout.Rectangle && layout !== WallLayout.Z);
    }
    return layouts;
  }

  get availableMountingTypes() {
    if (this.isInterna) {
      return [];
    }
    let mountingTypes = (this.engine.propertyValuesByPath(this.model, 'mountingType') ?? []) as MountingType[];
    mountingTypes = mountingTypes.filter(mountingType => mountingType !== MountingType.OnFloor);

    if (this.wallType !== WallType.Boxis) {
      mountingTypes = mountingTypes.filter(
        mountingType => mountingType !== MountingType.FixOnTheGround && mountingType !== MountingType.FixOnConcrete
      );
    }
    // if (this.model.layout === WallLayout.Z) {
    //   return mountingTypes.filter(mountingType => mountingType !== MountingType.Foot);
    // }
    if (this.model.layout === WallLayout.Standalone) {
      return [];
    }
    return mountingTypes;
  }

  get availableHeightsFromGround() {
    if (this.isBoxis) {
      switch (this.model.mountingType) {
        case MountingType.FixOnTheGround:
          return FIX_ON_THE_GROUND_HEIGHT_FROM_GROUND;
        case MountingType.FixOnConcrete:
          return FIX_ON_THE_CONCRETE_HEIGHT_FROM_GROUND;
        default:
          return [];
      }
    }
    return [];
  }

  get availableCentralBoxes() {
    return this.engine.optionValuesByPath(this.model, 'centralBox', new FlatStructuralTransformer<ICentralBox>()) ?? [];
  }

  get availableMailBoxes() {
    return this.engine.optionValuesByPath(this.model, 'mailBox', new FlatStructuralTransformer<IMailBox>()) ?? [];
  }

  get availableIntercoms() {
    const intercoms: Array<{ name: string; image: string }> = [];
    for (const centralBox of this.availableCentralBoxes) {
      if (![Intercom.No].includes(centralBox.model.intercom)) {
        if (!intercoms.find(intercom => intercom.name === centralBox.model.intercom)) {
          intercoms.push({ name: centralBox.model.intercom, image: centralBox.model.image });
        }
      }
    }
    return intercoms.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  get isFourMailboxesComplying() {
    return MIN_BOX_COUNT_COMPLIANCE_OPTIONS.includes(getOptionId(this.model.mailBox));
  }

  get availableMailboxHeight() {
    let heights = new Set<number>();
    for (const mailBox of this.availableMailBoxes) {
      heights.add(mailBox.model.height);
    }
    const minHeight = this.model.country.minMailboxHeight;

    return Array.from(heights)
      .filter(height => (minHeight === 150 ? height !== 100 : height !== 150))
      .sort();
  }

  get availableMailboxLockTypes(): MailboxLockType[] {
    const lockTypes = new Set<MailboxLockType>();
    for (const mailBox of this.availableMailBoxes) {
      lockTypes.add(mailBox.model.lockType);
    }
    return Array.from(lockTypes);
  }

  get availableMailboxLockNames() {
    const names = new Set<string>();
    for (const mailBox of this.availableMailBoxes) {
      names.add(mailBox.model.name);
    }
    return Array.from(names);
  }

  get availableParcelboxLockTypes(): ParcelLockType[] {
    if (!this.model.hasIntercom || this.model.parcelBoxesAmount > 1) {
      return [ParcelLockType.Number];
    } else {
      return [ParcelLockType.Number, ParcelLockType.Electric];
    }
  }
  get availableIntercomCutoutManufacturers() {
    const settings = new Set<string>();
    for (const setting of Object.keys(IntercomCutoutManufacturer)) {
      settings.add(setting);
    }
    return Array.from(settings);
  }

  get availableIntercomSettings() {
    const settings = new Set<string>();
    for (const setting of Object.keys(IntercomSetting)) {
      if (setting !== IntercomSetting.NA && setting !== IntercomSetting.TBD) {
        settings.add(setting);
      }
    }
    return Array.from(settings);
  }

  get availableCylinderCutouts(): CylinderCutout[] {
    if (this.hasOwnKeyPlanField && this.model.hasOwnKeyPlan &&  (this.isInterna || this.isBoxis)) {
      return [CylinderCutout.BuildInLock, CylinderCutout.CylinderOnly];
    }
    return [];
  }

  get hasAdditionalIntercomSetting() {
    const { intercom } = this.model.centralBox;
    return intercom === Intercom.Comelit || intercom === Intercom.Fermax;
  }

  get customIntercomDimensionsUnknown() {
    return this.model.intercomDimensionsUnknown;
  }

  get maxDoorbellsAmount() {
    return this.isDigital ? this.maxMailboxAmount : 8;
  }

  get minDoorbellsAmount() {
    return this.isDigital ? 0 : 1;
  }

  get zFormMaxTotalCells(): number {
    const layouts = ZFormLayoutGenerator.generateLayouts();
    const maxAmounts: number[] = [];
    for (const layout of layouts) {
      const { w1, w2, w3, h1, h2, h3 } = layout;
      let maxCells = w1 * h1 + w2 * (h1 + h2 + h3) + w3 * h3;
      maxAmounts.push(maxCells);
    }
    return Math.max(...maxAmounts);
  }

  get maxTotalCells(): number {
    switch (this.model.layout) {
      case WallLayout.Rectangle:
        return MAX_RECTANGLE_ROWS * MAX_COLUMNS_AMOUNT;
      case WallLayout.Standalone:
        return MAX_STANDALONE_ROWS * MAX_COLUMNS_AMOUNT;
      case WallLayout.Z: {
        return this.zFormMaxTotalCells;
      }
      default:
        throw new Error('Invalid layout');
    }
  }

  get centralBoxCells(): number {
    if (!this.model.hasCentralBox) {
      return 0;
    }
    if (this.model.wallType === WallType.Interna) {
      return InternaGenerator.getCUSize(this.model.customIntercom?.height) ?? 0;
    }
    if (this.model.wallType === WallType.Boxis) {
      return BoxisGenerator.getCUSize(this.model.customIntercom?.height) ?? 0;
    }

    return this.model.centralBox.rows * this.model.centralBox.columns;
  }

  get minParcelBoxSize(): number {
    if (this.model.wallType === WallType.Digital) {
      return 2;
    }
    if (this.model.wallType === WallType.Mechanical) {
      return 4;
    }
    return 0;
  }

  get parcelCells(): number {
    return this.model.parcelBoxesAmount * this.minParcelBoxSize;
  }

  get mailBoxSize(): number {
    return this.model.mailBox.rows * this.model.mailBox.columns;
  }

  get mailboxesCells(): number {
    return this.model.mailBoxesAmount * this.mailBoxSize;
  }

  get lightboxCells(): number {
    return this.model.hasLightBox ? this.model.mailBox.rows * 2 : 0;
  }

  get totalFreeCells(): number {
    return this.maxTotalCells - this.centralBoxCells - this.parcelCells - this.mailboxesCells - this.lightboxCells;
  }

  private wallLayoutsFreeCells() {
    let freeCells;
    switch (this.model.layout) {
      case WallLayout.Standalone:
      case WallLayout.Rectangle:
        // [8, 31] - [lowest mailbox position, higest mailbox position]
        const maxRows = 31 - 8 + 1;
        freeCells = MAX_COLUMNS_AMOUNT * maxRows;
        break;
      case WallLayout.Z:
        // mailboxes can be placed at any position
        freeCells = this.zFormMaxTotalCells;
        break;
    }
    return freeCells;
  }

  get maxMailboxAmount() {
    let freeCells = 0;
    switch (this.model.wallType) {
      case WallType.Mechanical:
      case WallType.Digital:
        freeCells = this.wallLayoutsFreeCells();
        break;
      case WallType.Interna:
        freeCells = InternaGenerator.MAX_ROWS * MAX_COLUMNS_AMOUNT;
        break;
      case WallType.Boxis:
        freeCells = BoxisGenerator.calculateMaxRows(this.model, this.model.cellHeight) * MAX_COLUMNS_AMOUNT;
        break;
    }
    console.log('freeCells', freeCells);

    freeCells -= this.centralBoxCells;

    return Math.floor(freeCells / this.mailBoxSize);
  }

  get maxParcelboxAmount() {
    const freeCells = this.maxTotalCells - this.centralBoxCells;

    return Math.floor(freeCells / this.minParcelBoxSize);
  }

  get suggestedParcelboxAmount() {
    let calculatedAmount;
    if (this.model.mailBoxesAmount < 40) {
      calculatedAmount = Math.trunc(this.model.mailBoxesAmount / 3);
    } else {
      calculatedAmount = Math.trunc(this.model.mailBoxesAmount / 4);
    }
    if (calculatedAmount > this.maxParcelboxAmount) {
      return this.maxParcelboxAmount;
    } else {
      return calculatedAmount;
    }
  }

  get isIntercomValid() {
    if (this.model.hasCustomIntercom && this.model.hasIntercom) {
      if (this.isDigital) {
        if (!this.model.customIntercom?.name) {
          //No custom intercom brand.
          return false;
        }
      } else {
        if (
          !(this.model.customIntercom?.height && this.model.customIntercom?.width) &&
          !(this.customIntercomDimensionsUnknown || this.model.customIntercom?.cutoutManufacturer === IntercomCutoutManufacturer.client)
        ) {
          //Should be custom intercom height and width or check 'I don't know dimensions yet'.
          return false;
        }
      }
    }
    if (this.model.centralBox.intercom === Intercom.Comelit) {
      if (![IntercomSetting.IP, IntercomSetting.TwoWire].includes(this.model.centralBox.intercomSetting)) {
        return false;
      }
    }
    if (this.model.centralBox.intercom === Intercom.Fermax) {
      if (![IntercomSetting.IP, IntercomSetting.TwoWire].includes(this.model.centralBox.intercomSetting)) {
        return false;
      }
    }
    return true;
  }

  get isValidForGenerateConfigurations(): { value: boolean; reason: string } {
    if (!this.isIntercomValid) {
      return { value: false, reason: 'Intercom name or dimensions are not valid.' };
    }
    if (!this.model.mailBoxesAmount && !this.model.parcelBoxesAmount) {
      return { value: false, reason: 'Should be at least one mail box or parcel box.' };
    }
    if (this.totalFreeCells < 0 || this.model.mailBoxesAmount > this.maxMailboxAmount) {
      return {
        value: false,
        reason: `Not enough space for boxes.`
      };
    }
    if (this.isBoxis || this.isInterna) {
      if (!this.availableMailboxLockNames.includes(this.model.mailBox.name)) {
        return {
          value: false,
          reason: 'Should be chosen mailbox'
        };
      }
    }
    return { value: true, reason: '' };
  }

  setCustomIntercomDimensionsUnknown(isUnknown: boolean) {
    this.model.intercomDimensionsUnknown = isUnknown;
    if (isUnknown && this.model.customIntercom) {
      this.setCustomIntercomDimensions({ width: 0, height: 0 });
    }
  }

  setCountry(countryName: string) {
    const countryTrimmed = countryName.toLowerCase().trim();
    if (!countryName) {
      let candidate = this.availableCountries.find(country => country.model.name.toLowerCase() === OTHER_COUNTRY);
      if (candidate) this.mutate(new SinglePropertyMutator('country', candidate));
    } else {
      let candidate = this.availableCountries.find(country => country.model.name.toLowerCase() === countryTrimmed);
      if (candidate) {
        this.mutate(new SinglePropertyMutator('country', candidate));
      } else {
        //set custom country name
        let candidate = this.availableCountries.find(country => country.model.name.toLowerCase() === OTHER_COUNTRY);
        if (candidate) this.mutate(new SinglePropertyMutator('country', candidate));
        this.model.country.name = countryName.trim();
      }
    }
    this.setMailboxesHeightToDefault(countryName);
  }

  setMailboxesHeightToDefault(countryName: string) {
    if (
      countryName.toLowerCase() === 'netherlands' &&
      this.model.mailBox.height !== 200 &&
      this.availableMailboxHeight.includes(200)
    ) {
      this.setMailboxesHeight(200);
    } else {
      if (!this.availableMailboxHeight.includes(this.model.mailBox.height)) {
        this.setMailboxesHeight(this.availableMailboxHeight[0]);
      }
    }
  }

  setWallLocation(wallLocation: WallLocation) {
    this.mutate(new SinglePropertyMutator('wallLocation', wallLocation));
    if (wallLocation === WallLocation.OutsideUnsheltered && this.model.hasLightBox) {
      this.setLightBox(false);
    }
    if (!this.availableLayouts.includes(this.model.layout)) {
      this.setWallLayout(this.availableLayouts[0]);
    }
    if (wallLocation === WallLocation.OutsideUnsheltered && this.wallType === WallType.Digital) {
      this.setWallLayout(WallLayout.Standalone);
    }
  }

  setWallLayout(wallLayout: WallLayout) {
    this.mutate(new SinglePropertyMutator('layout', wallLayout));
    if (this.model.layout === WallLayout.Standalone) {
      this.setMountingType(MountingType.OnFloor);
    } else {
      if (this.model.mountingType === MountingType.OnFloor) {
        this.setMountingType(MountingType.Hanging);
      }
    }
  }

  setMountingType(mountingType: MountingType) {
    this.mutate(new SinglePropertyMutator('mountingType', mountingType));
    if (this.isBoxis) {
      if (!this.model.heightFromGround || !this.availableHeightsFromGround.includes(this.model.heightFromGround)) {
        this.model.heightFromGround = this.availableHeightsFromGround[0] as HeightFromGround;
      }
    }
  }

  setHeightFromGround(height: HeightFromGround) {
    this.mutate(new SinglePropertyMutator('heightFromGround', height));
  }

  toLink(): string {
    return this.serializer.toLink(this.model);
  }

  private filterCentralBoxesByLocation(candidates: Option<ICentralBox>[]) {
    if (this.model.wallLocation === WallLocation.Inside) {
      return candidates.filter(box => box.model.location === CentralBoxLocation.Inside);
    }
    if (this.model.wallLocation === WallLocation.OutsideSheltered) {
      return candidates.filter(
        box =>
          box.model.location === CentralBoxLocation.OutsideLeft ||
          box.model.location === CentralBoxLocation.OutsideRight
      );
    }
    if (this.model.wallLocation === WallLocation.OutsideUnsheltered) {
      return candidates.filter(box => box.model.location === CentralBoxLocation.OutsideRight);
    }
    if (!this.model.wallLocation) {
      return candidates;
    }
    throw new Error(`Location '${this.model.wallLocation}' is not supported.`);
  }

  private setCentralBoxWithCustomIntercomHeight(height: number) {
    let candidates = this.availableCentralBoxes.filter(box => box.model.custom && box.model.height === height);

    if (candidates.length === 1) {
      this.mutate(new SinglePropertyMutator('centralBox', candidates[0]));
    } else {
      throw new Error('Cannot find custom');
    }
  }

  private setCentralBoxWithIntercomHeight(height: number) {
    let candidates = this.availableCentralBoxes.filter(box => box.model.height === height);

    if (candidates.length > 0) {
      this.mutate(new SinglePropertyMutator('centralBox', candidates[0]));
    } else {
      throw new Error('Cannot find interna central box.');
    }
  }

  setIntercom(intercom: string) {
    this.updateCentralBox(intercom, this.model.hasIntercom, this.model.hasCustomIntercom);
  }

  updateCentralBox(intercom: string, hasIntercom: boolean, hasCustomIntercom: boolean) {
    this.mutate(new SinglePropertyMutator('hasIntercom', hasIntercom));
    this.mutate(new SinglePropertyMutator('hasCustomIntercom', hasCustomIntercom));

    if (!hasIntercom || hasCustomIntercom) {
      this.setDoorBellsAmount(0);
    } else {
      const nonCustomDoorBellsAmount = this.isDigital ? this.model.mailBoxesAmount : this.minDoorbellsAmount;
      this.setDoorBellsAmount(this.customDoorBellsAmount ?? nonCustomDoorBellsAmount);
    }

    if (this.isDigital) {
      this.updateDigitalCentralBox(hasIntercom, hasCustomIntercom, intercom);
    } else if (this.isInterna) {
      this.updateInternaCentralBox(hasIntercom, hasCustomIntercom, intercom);
    } else if (this.isBoxis) {
      this.updateBoxisCentralBox(hasIntercom, hasCustomIntercom, intercom);
    } else {
      this.updateMechanicalCentralBox(hasIntercom, hasCustomIntercom, intercom);
    }
    return console.log(toJS(this.model));
  }

  private updateMechanicalCentralBox(hasIntercom: boolean, hasCustomIntercom: boolean, intercom: string) {
    if (hasIntercom) {
      this.setDoorbellsEnabled(hasCustomIntercom);
      this.setHasCentralBox(true);
      if (hasCustomIntercom) {
        const customIntercomHeight = this.model.customIntercom?.height ?? 0;
        if (!this.customIntercomDimensionsUnknown && customIntercomHeight > 350) {
          this.setCentralBoxWithCustomIntercomHeight(600);
        } else {
          this.setCentralBoxWithCustomIntercomHeight(400);
        }
      } else {
        // this.setHasAdditionalIntercomSetting(intercom);
        this.setCentralBoxWithIntercomAndWithBells(intercom);
        // this.setCentralBoxWithBellsByIntercom(intercom);
      }
    } else {
      this.doorBellsEnabled = false;
      this.setHasCentralBox(false);
    }
  }

  private updateInternaCentralBox(hasIntercom: boolean, hasCustomIntercom: boolean, intercom: string) {
    if (hasIntercom) {
      this.setDoorbellsEnabled(false);
      this.setHasCentralBox(true);
      if (hasCustomIntercom) {
        const customIntercomHeight = this.model.customIntercom?.height ?? 0;
        const customIntercomCutoutManufacturer = this.model.customIntercom?.cutoutManufacturer ?? IntercomCutoutManufacturer.esafe;
        if (!this.customIntercomDimensionsUnknown) {
          if (customIntercomHeight <= 250) {
            this.setCentralBoxWithIntercomHeight(330);
          } else if (customIntercomHeight <= 360) {
            this.setCentralBoxWithIntercomHeight(440);
          } else if (customIntercomHeight <= 470) {
            this.setCentralBoxWithIntercomHeight(550);
          }
        } else {
          this.setCentralBoxWithIntercomHeight(330);
        }

        this.setIntercomCutOutManufacturer(customIntercomCutoutManufacturer);
      } else {
        throw new Error('Interna can has only custom intercom.');
      }
    } else {
      this.doorBellsEnabled = false;
      this.setHasCentralBox(false);
    }
  }

  private updateBoxisCentralBox(hasIntercom: boolean, hasCustomIntercom: boolean, intercom: string) {
    if (hasIntercom) {
      this.setDoorbellsEnabled(hasCustomIntercom);
      this.setHasCentralBox(true);
      if (hasCustomIntercom) {
        const customIntercomHeight = this.model.customIntercom?.height ?? 0;
        const customIntercomCutoutManufacturer = this.model.customIntercom?.cutoutManufacturer ?? IntercomCutoutManufacturer.esafe;

        if (!this.customIntercomDimensionsUnknown) {
          if (customIntercomHeight <= 200) {
            this.setCentralBoxWithIntercomHeight(400);
          } else if (customIntercomHeight <= 535) {
            this.setCentralBoxWithIntercomHeight(600);
          }
        } else {
          this.setCentralBoxWithIntercomHeight(400);
        }

        this.setIntercomCutOutManufacturer(customIntercomCutoutManufacturer);
      } else {
        throw new Error('Boxis can has only custom intercom.');
      }
    } else {
      this.doorBellsEnabled = false;
      this.setHasCentralBox(false);
    }
  }

  private updateDigitalCentralBox(hasIntercom: boolean, hasCustomIntercom: boolean, intercom: string) {
    this.setHasCentralBox(true);
    if (hasIntercom) {
      this.setDoorbellsEnabled(hasCustomIntercom);
      if (hasCustomIntercom) {
        this.setCentralBoxWithCustomIntercomHeight(400);
      } else {
        this.setCentralBoxWithIntercomAndWithoutBells(intercom);
      }
    } else {
      this.doorBellsEnabled = false;
      this.setDisplayCentralBox();
    }
  }

  setDoorbellsEnabled(hasCustomIntercom: boolean) {
    this.doorBellsEnabled = !hasCustomIntercom;
  }

  setHasCentralBox(has: boolean) {
    this.mutate(new SinglePropertyMutator('hasCentralBox', has));
  }

  setHasIntercom(has: boolean) {
    if (this.isInterna || this.isBoxis) {
      this.updateCentralBox(this.model.centralBox.intercom, has, true);
    } else {
      this.updateCentralBox(this.model.centralBox.intercom, has, this.model.hasCustomIntercom);
    }
    if (!this.isDigital && !this.availableParcelboxLockTypes.includes(this.model.parcelLockType)) {
      this.setParcelLockType(this.availableParcelboxLockTypes[0]);
    }
  }

  setHasCustomIntercom(has: boolean) {
    if (!this.model.hasCustomIntercom && has) {
      // default value
      this.model.customIntercom = {
        name: '',
        width: 0,
        height: 0,
        cutoutManufacturer: IntercomCutoutManufacturer.esafe
      };
    }

    this.updateCentralBox(this.model.centralBox.intercom, this.model.hasIntercom, has);
  }

  setCustomIntercom(intercomBrand: string) {
    this.model.customIntercom = { name: intercomBrand, width: 0, height: 0, cutoutManufacturer: IntercomCutoutManufacturer.esafe };
    this.updateCentralBox(this.model.centralBox.intercom, this.model.hasIntercom, true);
  }

  setCustomIntercomCutoutManufacturer(intercomCutoutManufacturer: IntercomCutoutManufacturer) {
    this.model.customIntercom = { name: '', width: 0, height: 0, cutoutManufacturer: intercomCutoutManufacturer };
    this.updateCentralBox(this.model.centralBox.intercom, this.model.hasIntercom, true);
  }

  setCustomIntercomDimensions(dimensions: Partial<{ width: number; height: number }>) {
    if (dimensions.width !== undefined && dimensions.width > MAX_CUT_OUT_WIDTH(this.wallType)) {
      return new Error(`Custom intercom width should be maximum ${MAX_CUT_OUT_WIDTH(this.wallType)}.`);
    }

    if (dimensions.height !== undefined && dimensions.height > MAX_CUT_OUT_HEIGHT(this.wallType)) {
      return new Error(`Custom intercom width should be maximum ${MAX_CUT_OUT_WIDTH(this.wallType)}.`);
    }

    this.model.customIntercom = {
      name: '',
      width: dimensions.width ?? this.model.customIntercom?.width ?? 0,
      height: dimensions.height ?? this.model.customIntercom?.height ?? 0,
      cutoutManufacturer: this.model.customIntercom?.cutoutManufacturer ?? IntercomCutoutManufacturer.esafe
    };

    this.updateCentralBox(this.model.centralBox.intercom, true, true);
  }

  setLightBox(has: boolean) {
    this.mutate(new SinglePropertyMutator('hasLightBox', has));
  }

  get hasOwnKeyPlanField() {
    if (this.isDigital) {
      return this.model.mailBox.height === 200 && this.model.mailBox.lockType === MailboxLockType.Mechanical;
    } else if (this.wallType === WallType.Interna) {
      return this.model.mailBox.height === 220;
    } else if (this.wallType === WallType.Boxis) {
      return true;
    } else {
      return this.model.mailBox.height === 200;
    }
  }

  setOwnKeyPlan(has: boolean) {
    this.mutate(new SinglePropertyMutator('hasOwnKeyPlan', has));
  }

  setIntercomSetting(intercomSetting: IntercomSetting) {
    this.mutate(new SinglePropertyMutator('centralBox.intercomSetting', intercomSetting));
  }

  setIntercomCutOutManufacturer(intercomCutoutManufacturer: IntercomCutoutManufacturer) {
    this.mutate(new SinglePropertyMutator('centralBox.intercomCutoutManufacturer', intercomCutoutManufacturer));
    if (this.model.customIntercom) {
      this.model.customIntercom.cutoutManufacturer = intercomCutoutManufacturer;

      if (intercomCutoutManufacturer === IntercomCutoutManufacturer.client) {
        this.model.customIntercom.height = this.model.customIntercom.width = 0;
      }
    }
  }

  setMailBoxesAmount(amount: number) {
    this.mutate(new SinglePropertyMutator('mailBoxesAmount', amount));
  }

  setDoorBellsAmount(amount: number, custom: boolean = false) {
    this.mutate(new SinglePropertyMutator('doorBellsAmount', amount));

    if (custom) {
      this.customDoorBellsAmount = amount;
    }
  }

  setParcelBoxesAmount(amount: number) {
    this.mutate(new SinglePropertyMutator('parcelBoxesAmount', amount));
    if (!this.isDigital && amount > 1 && this.model.parcelLockType === ParcelLockType.Electric) {
      this.setParcelLockType(ParcelLockType.Number);
    }
  }

  setMailboxesHeight(height: number) {
    let candidates = this.availableMailBoxes.filter(mailbox => mailbox.model.height === height);
    if (candidates.length > 0) {
      const matchingCandidate = candidates.find(mailbox => mailbox.model.lockType === this.model.mailBox.lockType);
      if (matchingCandidate) {
        this.mutate(new SinglePropertyMutator('mailBox', matchingCandidate));
      } else {
        this.mutate(new SinglePropertyMutator('mailBox', candidates[0]));
      }
    }
  }

  setMailbox(mailboxName: string) {
    let candidates = this.availableMailBoxes.filter(mailbox => mailbox.model.name === mailboxName);
    if (candidates.length > 0) {
      const matchingCandidate = candidates.find(mailbox => mailbox.model.lockType === this.model.mailBox.lockType);
      if (matchingCandidate) {
        this.mutate(new SinglePropertyMutator('mailBox', matchingCandidate));
      } else {
        this.mutate(new SinglePropertyMutator('mailBox', candidates[0]));
      }
    }
  }

  setMailboxLockType(lockType: MailboxLockType) {
    let candidates = this.availableMailBoxes.filter(mailbox => mailbox.model.height === this.model.mailBox.height);

    candidates = candidates.filter(mailbox => mailbox.model.lockType === lockType);
    if (candidates.length > 0) {
      this.mutate(new SinglePropertyMutator('mailBox', candidates[0]));
    }
    console.log(toJS(this.model));
  }

  setParcelLockType(lockType: ParcelLockType) {
    this.mutate(new SinglePropertyMutator('parcelLockType', lockType));
  }

  private mutate(mutator: ModelMutator) {
    const [newModel, validationResult] = this.engine.mutate(this.model, mutator);
    if (validationResult.isInvalid) {
      if (process.env.NODE_ENV === 'production') {
        console.error(validationResult.errorMessage);
      } else {
        alert(validationResult.errorMessage);
      }
    } else {
      this.model = newModel as PModel<IWizard>;
    }
  }

  private setDisplayCentralBox() {
    let candidates = this.availableCentralBoxes.filter(box => box.model.display);

    candidates = this.filterCentralBoxesByLocation(candidates);

    if (candidates.length >= 1) {
      this.mutate(new SinglePropertyMutator('centralBox', candidates[0]));
    } else {
      throw new Error('Cannot find display central box');
    }
  }

  private setCentralBoxWithIntercomAndWithoutBells(intercom: string) {
    let candidates = this.filterCentralBoxesByLocation(this.availableCentralBoxes)
      // without bells
      .filter(candidate => candidate.model.maxBellsAmount === 0)
      .filter(candidate => candidate.model.intercom);

    if (intercom) {
      candidates = candidates.filter(candidate => candidate.model.intercom === intercom);
    }

    if (candidates.length > 0) {
      const centralBox = deepCopy(this.model.centralBox);
      this.mutate(new SinglePropertyMutator('centralBox', candidates[0]));
      if (centralBox.intercom === this.model.centralBox.intercom) {
        this.setIntercomSetting(centralBox.intercomSetting);
      }
    } else {
      throw new Error('Cannot find central box');
    }
  }

  private setCentralBoxWithIntercomAndWithBells(intercom: string) {
    let candidates = this.filterCentralBoxesByLocation(this.availableCentralBoxes)
      // without bells
      .filter(candidate => candidate.model.maxBellsAmount > 0)
      .filter(candidate => candidate.model.intercom);

    if (intercom) {
      if (intercom !== Intercom.No) {
        candidates = candidates.filter(candidate => candidate.model.intercom === intercom);
      }
    }

    if (candidates.length > 0) {
      this.mutate(new SinglePropertyMutator('centralBox', candidates[0]));
    } else {
      throw new Error('Cannot find central box');
    }
  }
  setCylinderCutout(cutout: CylinderCutout): void {
    this.model.cylinderCutout = cutout;
  }
}
